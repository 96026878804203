import React from "react";
import OrgMedia from "react-media";

const Media = ({children, ...props}) => {
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  React.useLayoutEffect(() => {
    setIsFirstRender(false);
  }, []);
  return <OrgMedia {...props}>{matches => children(isFirstRender ? false : matches)}</OrgMedia>;
};

export default Media;
