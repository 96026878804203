import React from "react";
import Img from "gatsby-image";
import {graphql, StaticQuery} from "gatsby";
import {Box} from "./xui/Box";

const query = graphql`
  query {
    imgs: allFile(filter: {relativePath: {glob: "imgs/suspects/*.{jpg,png}"}}) {
      nodes {
        name
        gray: childImageSharp {
          fixed(width: 100, height: 100, grayscale: true) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
        nonGray: childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`;

const sizeMap = {lg: "4", md: "2.5", sm: "1.5"};
const elevationMap = {md: "lg", sm: "sm", lg: "lg"};

const Avatar = React.forwardRef(({name, className, size = "md", nonGray}, ref) => (
  <StaticQuery query={query}>
    {({imgs: {nodes: imgs}}) => {
      const img = imgs.filter(i => i.name === name.toLowerCase())[0];
      return (
        img && (
          <Box
            as={Img}
            noShrink
            rounded="full"
            noOverflow
            backgroundSize="cover"
            innerRef={ref}
            width={sizeMap[size]}
            height={sizeMap[size]}
            className={className}
            fixed={nonGray ? img.nonGray.fixed : img.gray.fixed}
            style={{width: null, height: null}}
            borderWidth={1}
            borderColor="accent"
            elevation={elevationMap[size]}
          />
        )
      );
    }}
  </StaticQuery>
));

export default Avatar;
