import styled from "@emotion/styled";
import React from "react";

const CharOuter = styled("div")(({isOnTop, isOnLeft}) => ({
  position: "absolute",
  left: 0,
  right: 0,
  display: "flex",
  bottom: isOnTop ? "100%" : 0,
  ...(isOnLeft ? {marginLeft: "1rem"} : {justifyContent: "center"}),
}));

const Char = styled("img")({
  height: "3.5rem",
});

const Character = ({src, isOnTop, isOnLeft}) => (
  <CharOuter isOnTop={isOnTop} isOnLeft={isOnLeft}>
    <Char src={src} alt="rpg character" />
  </CharOuter>
);

export default Character;
